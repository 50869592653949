<template>
  <div class="page__container">
    <Header />
    <Burger />
    <MobileNav />
    <FlexContainer>
      <FlexItem size="25%" :shrink="false">
        <Navigation />
      </FlexItem>
      <FlexItem size="75%">
        <div
          class="page"
          :class="$route.meta.background === 'white' && 'u__background--white'"
        >
          <router-view :key="$route.fullPath" />
        </div>
      </FlexItem>
    </FlexContainer>
  </div>
</template>

<script>
import Navigation from '@/components/header/Navigation'
import Header from '@/components/header/Header'
import MobileNav from '@/components/header/MobileNav'
import Burger from '@/components/header/Burger'

export default {
  components: {
    Navigation,
    Header,
    MobileNav,
    Burger
  }
}
</script>

<style lang="scss" scoped>
.page__container {
  width: 100%;
  position: relative;
}

.page {
  width: 100%;
}
</style>
